/* eslint-disable @nx/enforce-module-boundaries */
import { ChevronDownIcon } from '@chakra-ui/icons';
import { ArrowDown, CloseBlueIcon } from '@mybridge/icons';
import {
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Stack,
} from '@mybridge/ui';
import { forwardRef, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDisclosure } from '@mybridge/ui';
import { FilterPopoverPopper } from 'v4/components/filter-keyword-popover';
export const SearchFilterKeywords = forwardRef(
  ({ title = 'Keyword', onChange, value, defaultValue, ...props }, ref) => {
    const { handleSubmit, register, reset } = useForm();

    const { onOpen, onClose, isOpen } = useDisclosure();

    const onSubmit = (form) => {
      console.log('form', form)
      console.log(form);
      onChange?.(form);
    };

    // useEffect(() => {
    //   if (!defaultValue) {
    //     reset();
    //   }
    // }, [defaultValue]);

    return (
      <>
        <form style={{width:"175px"}} onSubmit={handleSubmit(onSubmit)}>
          <FilterPopoverPopper
            placement="auto"
            trigger={
              <Button
                variant="transparent"
                fontSize="15px"
                color="brandGray.750"
                rightIcon={<ArrowDown />}
                justifyContent="space-between"
                w="100%"
                type="button"
              >
                {title}
              </Button>
            }
            withForm
            onPopClose={(e) => console.log()}
          >
            <Stack p={"20px"}>
              <Stack style={{display: 'flex', flexDirection: 'row',  justifyContent: 'space-between', alignItems: 'center'}}>
                <Heading size="md" fontWeight={400} color="#3D5A80">{title}</Heading>
              </Stack>
              <Stack direction={['column', 'row']}>
                <FormControl>
                  <FormLabel fontWeight={400}>First Name</FormLabel>
                  <Input style={{border: "1px solid #DDDDDD"}} placeholder="First Name" {...register('first_name')} />
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight={400}>Last Name</FormLabel>
                  <Input style={{border: "1px solid #DDDDDD"}} placeholder="Last Name" {...register('last_name')} />
                </FormControl>
              </Stack>
              <Stack direction={['column', 'row']}>
                <FormControl>
                  <FormLabel fontWeight={400}>Title</FormLabel>
                  <Input style={{border: "1px solid #DDDDDD"}} placeholder="Title" {...register('company_title')} />
                </FormControl>
              </Stack>
              <Stack direction={['column', 'row']}>
                <FormControl>
                  <FormLabel fontWeight={400}>Current Company</FormLabel>
                  <Input
                    placeholder="Current Company"
                    style={{border: "1px solid #DDDDDD"}}
                    {...register('current_company')}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight={400}>Past Company</FormLabel>
                  <Input
                    placeholder="Past Company"
                    style={{border: "1px solid #DDDDDD"}}
                    {...register('past_company')}
                  />
                </FormControl>
              </Stack>
              <Stack direction={['column', 'row']}>
                <FormControl>
                  <FormLabel fontWeight={400}>School</FormLabel>
                  <Input placeholder="School"  style={{border: "1px solid #DDDDDD"}} {...register('school')} />
                </FormControl>
              </Stack>
            </Stack>
          </FilterPopoverPopper>
        </form>
      </>
    );
  }
);
